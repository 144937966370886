export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
          Sharp, Focused, Brilliant - &copy; 2025 Laezr, LLC &nbsp; | &nbsp; <a href="/policies.html">Stripe Fulfillment Policy</a>
          </p>
        </div>
      </div>
    </div>
  )
}
